// Fonction pour gérer les événements click sur les togglers de menu
document.querySelectorAll('.menu-toggler').forEach(function (toggler) {
    toggler.addEventListener('click', function () {
        this.classList.toggle("menu-toggler--opened");

        const headerNavMenu = document.querySelector(".header-nav-menu");
        const handheldNavWrapper = document.querySelector(".handheld-header__nav-wrapper");
        const siteHeader = document.querySelector(".site-header");
        const headerNav = document.querySelector('.header__nav-menu');
        const handheldHeaderNav = document.querySelector('.handheld-header__nav'); // Sélection de l'élément à modifier

        if (headerNavMenu) {
            headerNavMenu.classList.toggle("menu--opened");
        }
        if (handheldNavWrapper) {
            handheldNavWrapper.classList.toggle("menu--opened");
        }
        if (siteHeader) {
            siteHeader.classList.toggle("menu--opened");
        }
        if (headerNav) {
            headerNav.classList.toggle("active");
        }
        if (handheldHeaderNav) {
            handheldHeaderNav.classList.toggle("menu--opened"); // Ajout de la classe .menu--opened à .handheld-header__nav
        }
    });
});

// Empêcher le comportement par défaut des liens avec href="#"
document.querySelectorAll('.header__nav a[href="#"]').forEach(function (link) {
    link.addEventListener('click', function (e) {
        e.preventDefault();
    });
});

// Ajouter des boutons pour les sous-menus dans le menu mobile si la largeur de la fenêtre est inférieure à 1199.5px
document.querySelectorAll(".menu-item-has-children").forEach(function (menuItem) {
    const button = document.createElement('button');
    button.classList.add('toggle-sub-menu');
    menuItem.appendChild(button);
    
    button.addEventListener('click', function () {
        menuItem.classList.toggle("menu-item--opened");
    });
});

// Gérer les sous-menus des éléments menu-item-has-children
document.querySelectorAll('.handheld-header__nav .menu-item-has-children').forEach(function (menuItem) {
    const button = document.createElement('button');
    button.classList.add('toggle-sub-menu');
    menuItem.appendChild(button);

    button.addEventListener('click', function () {
        menuItem.classList.toggle('menu-item--opened');

        const subMenuItems = menuItem.querySelectorAll('.sub-menu .menu-item a');
        subMenuItems.forEach(function (link, index) {
            link.style.setProperty('--secondary-menu-item-index', index + 1);
        });
    });
});

document.querySelectorAll('.handheld-header__nav > li > a').forEach(function (link, index) {
    link.style.setProperty('--secondary-menu-item-index', index + 1);
});
