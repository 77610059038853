class SiteHeader {
    constructor(el, stickyOffset) {
        this.el = el;

        this.isSticky = false;
        this.isTicking = false;
        this.stickyOffset = stickyOffset;
        this.lastScrollPosition = window.scrollY;
        this.updateFn = this._update.bind(this);

        if(this.el) {
            this._calculateHeaderHeight();
            this._onScroll();
            this._bindEvents();
        }
    }

    _bindEvents() {
        this.onScrollFn = this._onScroll.bind(this);
        this.onCalculateHeaderHeightFn = this._calculateHeaderHeight.bind(this);

        window.addEventListener('scroll', this.onScrollFn);
        window.addEventListener('resize', this.onCalculateHeaderHeightFn);
        window.addEventListener('load', this.onCalculateHeaderHeightFn);
    }

    _calculateHeaderHeight() {
        setTimeout(() => {
            document.documentElement.style.setProperty('--header-height', `${this.el.clientHeight}px`);
        }, 10);
    }

    _onScroll() {
        this.lastScrollPosition = window.scrollY;

        if(!this.isTicking) {
            this.isTicking = true;

            window.requestAnimationFrame(this.updateFn);
        }
    }

    _update() {
        this.isTicking = false;

        if(!this.isSticky && this.lastScrollPosition >= this.stickyOffset) {
            this.el.classList.add('site-header--sticky');
            this.isSticky = true;
        }
        else if(this.isSticky && this.lastScrollPosition < this.stickyOffset) {
            this.el.classList.remove('site-header--sticky');
            this.isSticky = false;
        }
    }
}

export default SiteHeader;