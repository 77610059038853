import EmblaCarousel from 'embla-carousel';
import DeviceManager from '../../utils/device-manager';
import $ from 'jquery';

class RealisationsSlider {
	constructor($el) {
		this.$el = $el;

		this.options = {
			loop: true,
            align: 'start',
            draggable: true,
            dragFree: false,
            containScroll: "trimSnaps", // Garde les slides dans le viewport
		};

		if (this.$el.length) {
			this._init();
			this._bindEvents();
		}
	}

	_init() {
		this.slider = new EmblaCarousel(this.$el[0], this.options);
		this._setupPrevNextButtons();
	}

	_bindEvents() {
		this._onBreakpointChangeFn = this._onBreakpointChange.bind(this);
		DeviceManager.on('device-manager:breakpointChange', this._onBreakpointChangeFn);
	}

	_setupPrevNextButtons() {
		const prevButton = this.$el.parent().find('.embla__button--prev').get(0);
		const nextButton = this.$el.parent().find('.embla__button--next').get(0);

		// Disable prev/next buttons if we can't go further in the direction
		const disablePrevNextBtns = () => {
			if (prevButton) prevButton.disabled = !this.slider.canScrollPrev();
			if (nextButton) nextButton.disabled = !this.slider.canScrollNext();
		};

		// Attach event listeners to buttons
		if (prevButton) prevButton.addEventListener('click', () => this.slider.scrollPrev());
		if (nextButton) nextButton.addEventListener('click', () => this.slider.scrollNext());

		// Update buttons state when the carousel is scrolled
		this.slider.on('select', disablePrevNextBtns);

		// Disable buttons at the start if necessary
		disablePrevNextBtns();
	}

	_destroy() {
		if (this.slider) {
			this.slider.destroy(true, true);
			this.slider = null;
		}
	}

	_onBreakpointChange() {
	}
}

export default RealisationsSlider;
