import $ from 'jquery';
import SiteHeader from "./components/site-header";
import DeviceManager from "./utils/device-manager";
import RealisationsSlider from "./components/sections/realisations-carousel";
import LogosSlider from "./components/sections/logos-carousel";

import './components/handheld-menu';
import './components/sections/header-animation';
import './components/sections/accordions';
import './components/sections/parallax-contact';
import "./components/sections/jobs-slider";

$(function () {

	const bootstrapApp = () => {
		const $html = $("html");

		// Touch device detection
		if (DeviceManager.isHandheld()) {
			$html.addClass("touch");

			if (DeviceManager.isIos()) {
				$html.addClass("ios");
			}
		} else {
			$html.addClass("no-touch");
		}

		// IE detection
		if (DeviceManager.isIe11()) {
			$html.addClass("ie11");
		} else if (DeviceManager.isEdge()) {
			$html.addClass("edge");
		}
	}
	
	const instanciateComponents = () => {
		// General components
		const siteHeader = document.querySelector('.site-header');

		if(siteHeader) {
			new SiteHeader(siteHeader, 20);
		}	

		// Realisations component
		const $realisationsSliders = $(".realisations-section__container .embla__viewport");
		if ($realisationsSliders.length) {
			$realisationsSliders.each(function (index, el) {
				const realisationSlider = new RealisationsSlider($(el));
			});
		}
		
		// Logos component
		const $logosSliders = $(".logo-slider-section__container .embla__viewport");
		if ($logosSliders.length) {
			$logosSliders.each(function (index, el) {
				const logoSlider = new LogosSlider($(el));
			});
		}
	}

	bootstrapApp();
	instanciateComponents();


});

