
document.addEventListener('DOMContentLoaded', function () {
    
    if (document.querySelector('.section-contact')) {
        // Créer un observateu d'intersection
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                const container = document.querySelector('.section-contact .section-contact__container');

                if (entry.isIntersecting) {
                    // Section est visible, appliquer l'effet de parallaxe
                    window.addEventListener('scroll', applyParallax);
                    applyParallax(); // Appliquer immédiatement l'effet de parallaxe
                } else {
                    // Section n'est plus visible, retirer l'effet de parallaxe
                    window.removeEventListener('scroll', applyParallax);
                    // Réinitialiser la position
                    if (container) {
                        container.style.transform = 'translateY(300%)'; // Remettre la position initiale
                    }
                }
            });
        }, {
            threshold: 0.1 // Déclenche l'observation quand 20% de la section est visible
        });

        // Observer la section
        const sectionContact = document.querySelector('.section-contact');
        if (sectionContact) {
            observer.observe(sectionContact);
        }

        // Fonction d'application de l'effet de parallaxe
        function applyParallax() {
            const scrollPosition = window.pageYOffset;
            const windowHeight = window.innerHeight;
            const sectionTop = sectionContact.offsetTop;
            const sectionHeight = sectionContact.offsetHeight;
            const middleOfScreen = scrollPosition + (windowHeight / 2); // Milieu de l'écran

            // Calculer le point milieu de la section
            const sectionMiddle = sectionTop + (sectionHeight / 2);
            const parallaxSpeed = 0.3; // Ajuster la vitesse du parallaxe

            // Appliquer l'effet de parallaxe uniquement si la section atteint le milieu de l'écran
            if (middleOfScreen >= sectionTop && middleOfScreen <= (sectionTop + sectionHeight)) {
                const distanceScrolled = (middleOfScreen - sectionMiddle) * parallaxSpeed;
                const container = document.querySelector('.section-contact .section-contact__container');
                
                if (container) {
                    container.style.transform = `translateY(${Math.max(50 - distanceScrolled, 0)}px)`; // Monte du bas vers le haut
                }
            }
        }

        // Détecter le défilement
        window.addEventListener('scroll', applyParallax);

        // Appliquer immédiatement l'effet de parallaxe si nécessaire
        applyParallax();
    }
});