document.addEventListener('DOMContentLoaded', () => {
    const jobsSlider = document.querySelector('.jobs-section');

    if (jobsSlider) {
        // Selecting Elements
        const sticky = document.querySelector('.sticky');
        const stickyParent = document.querySelector('.sticky-parent');
        const frame = document.querySelector('.sticky-parent .frame');
        const horizontalContainer = document.querySelector('.horizontal');

        // Variables to store widths
        let scrollWidth = 0;
        let totalJobsWidth = 0;
        let lastJobCenterPosition = 0;
        let verticalScrollHeight = stickyParent.scrollHeight - window.innerHeight;

        // Speed factor for faster scrolling
        const speedFactor = 1.5;

        // Adding scroll and resize event listeners
        document.addEventListener('scroll', horizontalScroll);
        window.addEventListener('resize', updateScrollWidth);
        window.addEventListener('load', () => {
            updateScrollWidth();
            calculateJobsWidth();
        });

        // Function to calculate the total width of the .job elements
        function calculateJobsWidth() {
            totalJobsWidth = 0;
            const jobs = document.querySelectorAll('.job');

            jobs.forEach(job => {
                totalJobsWidth += job.getBoundingClientRect().width;
            });

            // Get the last job element
            const lastJob = jobs[jobs.length - 1];
            const lastJobRect = lastJob.getBoundingClientRect();
            const lastJobWidth = lastJobRect.width;

            // Calculate the center position of the last .job relative to the horizontal container
            lastJobCenterPosition = (totalJobsWidth - lastJobWidth / 2 + window.innerWidth / 2) + 150;
        }

        // Function to update scroll width when page loads or is resized
        function updateScrollWidth() {
            scrollWidth = horizontalContainer.scrollWidth;
            verticalScrollHeight = stickyParent.scrollHeight - window.innerHeight;
        }

        // Scroll function
        function horizontalScroll() {
            const stickyParentRect = stickyParent.getBoundingClientRect();

            // Only start scrolling when the stickyParent is in view
            if (stickyParentRect.top <= 0 && stickyParentRect.bottom > 0) {
                const scrolled = Math.abs(stickyParentRect.top);
                const maxScrollLeft = lastJobCenterPosition - window.innerWidth;

                // Calculate the horizontal scroll position
                let horizontalScroll = (scrolled / verticalScrollHeight) * maxScrollLeft * speedFactor;

                // Ensure horizontalScroll stays within bounds
                horizontalScroll = Math.min(horizontalScroll, maxScrollLeft);
                horizontalScroll = Math.max(horizontalScroll, 0);

                // Apply the calculated horizontal scroll position
                horizontalContainer.style.transform = `translateX(-${horizontalScroll}px)`;
            } else if (stickyParentRect.bottom <= 0) {
                // Keep the container at the max scroll position
                horizontalContainer.style.transform = `translateX(-${lastJobCenterPosition - window.innerWidth}px)`;
            } else {
                // Reset horizontal scroll position if stickyParent is not in view
                horizontalContainer.style.transform = `translateX(0)`;
            }

            // Keep the .frame fixed horizontally
            frame.style.transform = 'translateX(0)';
        }
    }
});
