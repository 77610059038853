import EmblaCarousel from 'embla-carousel';
import DeviceManager from '../../utils/device-manager';
import $ from 'jquery';

class LogosSlider {
	constructor($el) {
		this.$el = $el;

		this.options = {
			loop: true,
			align: 'center',
			watchDrag: false,
			dragFree: false,
		};

		if (this.$el.length) {
			this._init();
			this._bindEvents();
		}
	}

	_init() {
		this.slider = new EmblaCarousel(this.$el[0], this.options);
		this._setupPrevNextButtons();
		this._setupDots(); // Appelle la méthode pour générer les dots
	}

	_bindEvents() {
		this._onBreakpointChangeFn = this._onBreakpointChange.bind(this);
		DeviceManager.on('device-manager:breakpointChange', this._onBreakpointChangeFn);
	}

	_setupPrevNextButtons() {
		const prevButton = this.$el.parent().find('.embla__button--prev').get(0);
		const nextButton = this.$el.parent().find('.embla__button--next').get(0);

		// Désactiver les boutons prev/next si on ne peut pas aller plus loin
		const disablePrevNextBtns = () => {
			if (prevButton) prevButton.disabled = !this.slider.canScrollPrev();
			if (nextButton) nextButton.disabled = !this.slider.canScrollNext();
		};

		// Attacher les écouteurs d'événements aux boutons
		if (prevButton) prevButton.addEventListener('click', () => this.slider.scrollPrev());
		if (nextButton) nextButton.addEventListener('click', () => this.slider.scrollNext());

		// Mettre à jour l'état des boutons quand le carousel est scrollé
		this.slider.on('select', disablePrevNextBtns);

		// Désactiver les boutons au départ si nécessaire
		disablePrevNextBtns();
	}

	_setupDots() {
		const dotsContainer = this.$el.parent().find('.embla__dots').get(0); // Sélectionne le conteneur des dots

		if (!dotsContainer) return; // Si pas de conteneur, ne rien faire

		const generateDots = () => {
			const slides = this.slider.slideNodes().length;
			dotsContainer.innerHTML = '';

			for (let i = 0; i < slides; i++) {
				const dot = document.createElement('button');
				dot.classList.add('embla__dot');
				dot.type = 'button';
				dotsContainer.appendChild(dot);

				dot.addEventListener('click', () => this.slider.scrollTo(i));
			}
		};

		const setActiveDot = () => {
			const allDots = dotsContainer.querySelectorAll('.embla__dot');
			const selectedIndex = this.slider.selectedScrollSnap();

			allDots.forEach((dot, index) => {
				dot.classList.toggle('is-active', index === selectedIndex);
			});
		};

		// Générer les dots lors de l'initialisation et des changements
		this.slider.on('init', () => {
			generateDots();
			setActiveDot();
		});
		this.slider.on('select', setActiveDot);

		// Initialisation au cas où le carousel est déjà monté
		generateDots();
		setActiveDot();
	}

	_destroy() {
		if (this.slider) {
			this.slider.destroy(true, true);
			this.slider = null;
		}
	}

	_onBreakpointChange() {}
}

export default LogosSlider;
