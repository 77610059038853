document.addEventListener('DOMContentLoaded', function () {
    const accordionButtons = document.querySelectorAll('.accordion-button');

    accordionButtons.forEach(function (button) {
        button.addEventListener('click', function () {
            const targetId = this.getAttribute('data-bs-target');
            const target = document.querySelector(targetId);
            const isOpen = target.classList.contains('show');

            // Fermer uniquement l'accordéon actuel si ouvert
            if (isOpen) {
                target.style.maxHeight = '0'; // Réduit la hauteur à 0
                setTimeout(() => {
                    target.classList.remove('show');
                    target.style.maxHeight = null; // Réinitialise après la transition
                }, 300); // Doit correspondre à la durée de la transition
                this.classList.remove('show');
            } else {
                // Fermer tous les autres accordéons
                const parentAccordion = this.closest('.accordion');
                parentAccordion.querySelectorAll('.accordion-collapse.show').forEach(function (openAccordion) {
                    openAccordion.style.maxHeight = '0'; // Réduit la hauteur à 0
                    setTimeout(() => {
                        openAccordion.classList.remove('show');
                        openAccordion.style.maxHeight = null; // Réinitialise après la transition
                    }, 300);
                });

                parentAccordion.querySelectorAll('.accordion-button.show').forEach(function (openButton) {
                    openButton.classList.remove('show');
                });

                // Ouvre l'accordéon sélectionné
                target.classList.add('show');
                target.style.maxHeight = target.scrollHeight + 'px'; // Définit la hauteur pour l'animation
                setTimeout(() => {
                    target.style.maxHeight = null; // Réinitialise pour permettre des transitions fluides à l'avenir
                }, 300); // Réinitialise après la transition
                this.classList.add('show');
            }
        });
    });
});
