import EventEmitter from 'emitter-component';
import isHandheld from './is-handheld';

var DeviceManager = new EventEmitter();

var breakpoints = {
    tablet: 768,
    desktop: 992,
    desktopLarge: 1200
};

DeviceManager.getDevice = function() {
    var device = {
        type: DeviceManager.getDeviceType()
    };
    return device;
};

DeviceManager.getDeviceType = function() {
    var device = 'mobile';

    if(DeviceManager.isIphone() || (DeviceManager.isAndroid() && window.innerWidth < breakpoints['tablet']) || window.innerWidth < breakpoints['tablet']) {
        device = 'mobile';
    }
    else if(DeviceManager.isIpad() || (DeviceManager.isAndroid() && window.innerWidth < breakpoints['desktop']) || window.innerWidth < breakpoints['desktop']) {
        device = 'tablet';
    }
    else if(window.innerWidth >= breakpoints['desktop'] && window.innerWidth < breakpoints['desktopLarge']) {
        device = 'desktop';
    }
    else if(window.innerWidth >= breakpoints['desktopLarge']) {
        device = 'desktopLarge';
    }

    return device;
};

DeviceManager.getCurrentBreakpoint = function() {
    var breakpoint = 'mobile';

    if(window.innerWidth >= breakpoints['tablet'] && window.innerWidth < breakpoints['desktop']) {
        breakpoint = 'tablet';
    }
    else if(window.innerWidth >= breakpoints['desktop'] && window.innerWidth < breakpoints['desktopLarge']) {
        breakpoint = 'desktop';
    }
    else if(window.innerWidth >= breakpoints['desktopLarge']) {
        breakpoint = 'desktopLarge';
    }

    return breakpoint;
};

DeviceManager.isMobile = function() {
    return DeviceManager.getDeviceType() == 'mobile';
};

DeviceManager.isDesktop = function() {
    return DeviceManager.getCurrentBreakpoint() == 'desktop';
};

DeviceManager.isAndroid = function() {
    var ua = navigator.userAgent;
    return ua.indexOf('android') != -1 || ua.indexOf('Android') != -1;
};

DeviceManager.isIos = function() {
    return DeviceManager.isIphone() || DeviceManager.isIpad();
};

DeviceManager.isIphone = function() {
    var ua = navigator.userAgent;
    return ua.indexOf('iPhone') != -1;
};

DeviceManager.isIpad = function() {
    var ua = navigator.userAgent;
    return ua.indexOf('iPad') != -1;
};

DeviceManager.isHandheld = function() {
    return isHandheld();
};

DeviceManager.isIe10 = function() {
	if(navigator.appVersion.indexOf('MSIE 10') !== -1) {
	    return true;
	}

	return false;
};

DeviceManager.isIe11 = function() {
	var UAString = navigator.userAgent;
	if(UAString.indexOf('Trident') !== -1 && UAString.indexOf('rv:11') !== -1) {
	    return true;
	}

	return false;
};

DeviceManager.isEdge = function() {
	var UAString = navigator.userAgent;
	if(/Edge/.test(UAString)) {
	    return true;
	}

	return false;
};

DeviceManager.supportsObjectFit = function() {
    return 'objectFit' in document.documentElement.style;
};

DeviceManager.calculateScrollbarWidth = function() {
	// Create the measurement node
	var scrollDiv = document.createElement('div');
	scrollDiv.className = 'scrollbar-measure';
	document.body.appendChild(scrollDiv);

	// Get the scrollbar width
	var scrollBarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;

	// Delete the DIV
	document.body.removeChild(scrollDiv);

	// Inject calculated style
	var style = document.createElement('style');
	style.type = 'text/css';
	style.innerHTML = '.max-viewport-width {max-width: calc(100vw - ' + scrollBarWidth + 'px)}';
	document.head.appendChild(style);
};

DeviceManager.getRenderingRatio = function() {
    var devicePixelRatio = window.devicePixelRatio || 1;
    var context = document.createElement('canvas').getContext('2d');
    var backingStorePixelRatio = context.webkitBackingStorePixelRatio ||
                                context.mozBackingStorePixelRatio ||
                                context.msBackingStorePixelRatio ||
                                context.oBackingStorePixelRatio ||
                                context.backingStorePixelRatio || 1;
    return Math.min(devicePixelRatio / backingStorePixelRatio, 2);
};

DeviceManager.device = DeviceManager.getDevice();
DeviceManager.previousDevice = DeviceManager.device;
DeviceManager.currentBreakpoint = DeviceManager.getCurrentBreakpoint();
DeviceManager.previousBreakpoint = DeviceManager.currentBreakpoint;

window.addEventListener('resize', function() {
    var breakpoint = DeviceManager.getCurrentBreakpoint();
    var device = DeviceManager.getDevice();

    if(DeviceManager.currentBreakpoint !== breakpoint) {
        DeviceManager.previousDevice = DeviceManager.device;
        DeviceManager.device = device;

        DeviceManager.previousBreakpoint = DeviceManager.currentBreakpoint;
        DeviceManager.currentBreakpoint = breakpoint;

        DeviceManager.emit('device-manager:breakpointChange');
    }
});

export default DeviceManager;
