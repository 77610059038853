document.addEventListener('DOMContentLoaded', function () {
    const svgs = document.querySelectorAll('.header-page .svg-right, .header-page .svg-centered');

    if (svgs.length > 0) {
        // Get the initial window width
        function getInitialRightPosition(svgClass) {
            if (window.innerWidth <= 500) {
                return svgClass === 'svg-right' ? -65 : 0;
            } else if (window.innerWidth <= 768) {
                return svgClass === 'svg-right' ? -25 : 12.5;
            } else {
                return svgClass === 'svg-right' ? -35 : 32.5;
            }
        }

        // Initialise the positions of the SVG
        svgs.forEach(svg => {
            const className = svg.classList.contains('svg-right') ? 'svg-right' : 'svg-centered';
            const initialRightPosition = getInitialRightPosition(className);
            svg.style.right = `${initialRightPosition}%`;

            // Scroll event to move the SVG
            window.addEventListener('scroll', function () {
                const scrollY = window.scrollY;
                let newPosition;

                if (className === 'svg-right') {
                    newPosition = Math.min(scrollY / 2, 100);
                } else {
                    newPosition = Math.min(scrollY / 2, 60);
                }

                svg.style.right = `${initialRightPosition + newPosition}%`; // Adjust the position according to the scroll
            });
        });

        // Set the position with the media-queries on resize
        window.addEventListener('resize', function () {
            svgs.forEach(svg => {
                const className = svg.classList.contains('svg-right') ? 'svg-right' : 'svg-centered';
                const initialRightPosition = getInitialRightPosition(className);
                svg.style.right = `${initialRightPosition}%`; // Updates initial position
            });
        });
    }
});
